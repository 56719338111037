import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "./index.css";

const App = React.lazy(() => import("./App"));
const LinkTree = React.lazy(() => import("./LinkTree"));

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <React.Suspense fallback={null}>
        <Switch>
          <Route path="/linktree" exact component={LinkTree} />
          <Route path="/" component={App} />
        </Switch>
      </React.Suspense>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
